<template>

    <div class="title">Profile</div>
    <div class="container">
        <table class="profile">
            <tbody>
                <template v-for="entry in Object.entries(data.user)">
                    <template v-if="entry[0]=='password'">
                        <tr>
                            <th class="key">
                                {{ entry[0] }}:
                            </th>
                            <td >
                                <input type="password" @change.stop="update_user(data.user,entry[0])" class="value" v-model="data.user[entry[0]]" contenteditable>
                            </td>
                        </tr>
                    </template>
                    <template v-else-if="entry[0]!='id'">
                        <tr>
                            <th class="key">
                                {{ entry[0] }}:
                            </th>
                            <td >
                                <input @change.stop="update_user(data.user,entry[0])" class="value" v-model="data.user[entry[0]]" contenteditable>
                            </td>
                        </tr>
                    </template>
                </template>
    
    
            </tbody>
    
        </table>

    </div>



</template>



<script setup>
    import { ref,reactive,onBeforeMount } from 'vue';
    import {useStore} from 'vuex'
    import axios from 'axios'


    let store=useStore()
    let user=store.state.user
    let data=reactive({user:user})
    onBeforeMount(()=>{


        get_user(data.user)

    })


    async function get_user(user){
        let response=await axios.post('/users/get_user/',{user_id:user.id})
        data.user=response.data
    }

    async function update_user(user,key){
        console.log('PATCHING',user[key])
        let response=await axios.post('/users/update_user/',{user_id:user.id,key:key,value:data.user[key]})

        data.user=response.data

    }


</script>


<style scoped>

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        gap: 1rem;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .profile{
        align-self: center;
        justify-self: center;
        text-align: center;
    }


    .key{

        
    }

    .value{
        width:100%;
        height: 100%;

    }

    
    .value:hover{
        background: var(--orange);
    }
    
    .value:focus{
        background: var(--red);
        color:var(--white);
    }

</style>
<template>
    <div class="durations-container" :class="{show:show, hide:hide}">
        <template v-for="duration in durations">
            <template v-if="attendance.duration==duration">
                <button class="selected" :class="{show_buttons:show, hide_buttons:hide}">
                    {{ duration }}
                </button> 
    
            </template>
            <template v-else>
                <button @click.stop="update_duration(attendance,duration)" :class="{show_buttons:show, hide_buttons:hide}">
                    {{ duration }}
                </button> 
    
            </template>
        </template>
    </div>
</template>


<script setup>
  import {ref,reactive,inject,onUpdated} from 'vue'
  import axios from 'axios'
  
  
  
  let durations=ref([
    "0.25",
    "0.50",
    "0.75",
    "1.00",
    "1.25",
    "1.50",
    "1.75",
    "2.00",
    "2.50",
    "3.00",
    "3.50",
    "4.00",
  ])

  defineProps({
    attendance:null,
    show:null,
    hide:null,

  })




  async function update_duration(attendance,duration){
    let response = await axios.post('/instructors/update_duration/',{attendance_id:attendance.id,duration:duration})


    Object.assign(attendance,response.data)


  }








</script>


<style scoped>


.durations-container{
    /* position:relative; */
    height:0%;
    width: 0%;
    /* font-size:0px; */
    background:transparent;
    display:flex;
    align-items:center;
    justify-content: center;
    overflow:auto;
}

.selected{
    background:var(--green);
    width: 30px;
    height: 30px;
    font-size: larger;
    padding:5px;
}






.show_buttons{

animation-name: show-buttons;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--home)*2); */
/* animation-iteration-count: iteration-count; */
animation-direction: normal;
animation-fill-mode: forwards;

--height:20px;
--width:35px;

}


@keyframes show-buttons {
  0%{
    min-width:0%;
    width:0%;
    max-width:0%;
    min-height:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    margin:0px;
    padding:0px;
  }

  100%{
    min-width:var(--width);
    width:var(--width);
    max-width:var(--width);
    min-height:var(--height);
    height:var(--height);
    max-height:var(--height);
    
    font-size:medium;

    
  }

}




.hide_buttons{


animation-name: hide-buttons;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
animation-direction: normal;
animation-fill-mode: forwards;
}




@keyframes hide-buttons {
0%{
  min-height:initial;
  width:initial;
  max-width:initial;
  min-height:initial;
  height:initial;
  max-height:initial;
  font-size:medium;
  /* padding:initial;
  margin:initial; */
  /* gap:initial; */
}

100%{
  min-width:0%;
  width:0%;
  max-width:0%;
  min-height:0%;
  height:0%;
  max-height:0%;
  font-size:0px;
  padding:0px;
  margin:0px;
  gap:0px;
}
}


@media (max-width:1000px){
  .show{
    justify-content: start;
    max-width: 100px;
  }
}



</style>
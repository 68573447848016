<template>
  <section @click.stop="updatepane" :class="{fullscreen:sectionfullscreen}">
    <div class="title" v-if="sectionfullscreen">
      {{ currentmodel }}
    </div>

    <div class="objects" v-if="selectionmode">
      <div @click.stop="toggleselected(object)" class="object" :class="{selected:object.id in selected}" v-for="object in objects">
        <template v-if="object.past_id">
          {{object.past_id}} 

        </template>
        
        {{ object.ui }} 

      </div>

    </div>


    <div v-else class="objects">

      <template v-for="object in objects">
        <div @click.stop="toggleobject(object)" class="object" :class="{fullscreen:object.fullscreen}">
          
          <div class="title">
            <template v-if="object.past_id">
              {{object.past_id}} 

            </template>
            
            {{ object.ui}} 
          </div>
        
          <div class="entries" v-if="object.fullscreen">
            
  
            <div class="subentries">
              <template v-for="entry in object.longentries">
                <template v-if="!['longentries','shortentries'].includes(entry[0])">
                  <div @click.stop="toggleentry(entry,object)" class="entry">
                    {{ entry[0] }}
                  </div>
                </template>
    
              </template>
            </div>
      
            <div class="subentries">
              <template v-for="entry in object.shortentries">
                <template v-if="!['fullscreen'].includes(entry[0])">
                  <div @click.stop class="entry">
                    {{ entry[0] }}
                    <textarea v-model="entry[1]" @input="patch(object,entry[0],entry[1])"/>
                  </div>
                </template>

              </template>
            </div>
  
          </div>
        
        
            
        </div>
        
  
  
  
      </template>

    </div>

    <button @click.stop="buttonsshowfunction" class="buttonsshow" v-show="sectionfullscreen"> 
      +
    </button>
    <div class="buttons" v-show="buttonsshow">
      <button @click.stop="toggleselection">Selection Mode</button>
      <button @click.stop="selectall">Select All</button>
      <button @click.stop="show_move_copy">Move/Copy</button>
      <button @click.stop="addnew">Add-New</button>
      <button @click.stop="showaddfromlist">Add-From List</button>

      <button @click.stop="remove">Remove</button>




    </div>


  </section>

  <Pane ref="pane"/>
  <Move ref="move"/>
  <Add ref="addpane"/>


</template>



<script setup>

import {ref,onBeforeUpdate,onUpdated} from 'vue'
import Pane from '@/components/Pane7.vue'
import Move from '@/components/Move.vue'
import Add from '@/components/Add.vue'
import axios from 'axios';

  
  
  
  let objects=ref()
  let sectionfullscreen=ref()
  let currentmodel=ref()
  let pane=ref()
  let move=ref()
  let addpane=ref()

  let selected=ref({})


  let buttonsshow=ref(false)
  let selectionmode=ref(false)

  let parents=ref()
  let parent=ref(null)

  onUpdated(()=>{

    
    if(!active.value){
      return
    }
    objects.value.forEach(object =>{
      let entries=Object.entries(object);
      object.shortentries=[]
      object.longentries=[]
      entries.forEach(entry=>{
        if (typeof entry[1] == 'object'){
          object.longentries.push(entry)
        }else{
          object.shortentries.push(entry)
        }
      })

    })

  })


  function updatepane(){
    if (buttonsshow.value || selectionmode.value || move.value.show || addpane.value.show){
      buttonsshow.value=false
      selectionmode.value=false
      selected.value={}
      move.value.show=false
      addpane.value.show=false
      if (addpane.value.basemodels){
        objects.value=addpane.value.basemodels
        addpane.value.basemodels={}
      }

      return
    }

    sectionfullscreen.value=false
    objects.value=[]


  }

  let active=ref(true)
  async function toggleobject(mainobject){
    active.value=!active.value
    mainobject.fullscreen=!mainobject.fullscreen

    let response=await axios.post('/database/get_object/',{object:mainobject})
    mainobject=response.data
    objects.value=objects.value.map(object=>{
      if(object.id==mainobject.id){

        object=Object.assign(object,mainobject)
      }
      return object
    })



  }

  async function toggleentry(entry,object){
    let model=entry[0]

    let response=await axios.post(`/database/get_filtered_objects/`,{parent:object,model:model})

    pane.value.objects=response.data
    pane.value.currentmodel=model
    pane.value.sectionfullscreen=true
    pane.value.parents=objects.value
    pane.value.parent=object

  }

  function buttonsshowfunction(){
    buttonsshow.value=!buttonsshow.value
  }
  
  function toggleselection(){
    selectionmode.value=!selectionmode.value

  }


  function toggleselected(object){
    if (object.id in selected.value){
      delete selected.value[object.id]
      return
    }

    selected.value[object.id]=object
  }

  function show_move_copy(){

    move.value.show=true
    move.value.selectedchildren=selected.value
    move.value.parents=parents.value

  }
  


  async function showaddfromlist(){

    let response=await axios.post('/database/get_objects/',{model:currentmodel.value})

    addpane.value.models=response.data
    addpane.value.selectedmodelstring=currentmodel.value
    addpane.value.parent=parent.value

    addpane.value.show=true

  
  }




  async function addnew(){




    let response=await axios.post('/database/addnew/',{model:currentmodel.value,parentmodel:parent.value})
    objects.value=response.data
  }

  async function remove(){
    let response=await axios.post('/database/remove/',{selected:selected.value,selectedmodelstring:currentmodel.value, parent:parent.value})
    objects.value=response.data
    selected.value={}
  }


  async function patch(object,entryname,entryvalue){

    let response=await axios.post('/database/patch/',{object:object,attribute:entryname,value:entryvalue})
    object=response.data
    console.log(object)

  }

  function selectall(){
    objects.value.forEach(object=>{
      toggleselected(object)
    })

  }





  defineExpose({
    objects,
    sectionfullscreen,
    currentmodel,
    parents,
    parent,

  })


</script>



<style scoped>

  section {
    background: var(--blue);
    width: 100vw;
    color: var(--white);
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    top: -100vh;
    transition: all 0.2s linear;


  }


  
  .title{
    font-size: xx-large;
    color: var(--white);
    text-align: center;
    text-justify: center;
    height: auto;
  }


  .objects {
    background: var(--blue);
    display: grid;
    height: 100%;
    width: inherit;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit,minmax(5rem,20rem));
    /* grid-template-rows: repeat(auto-fit,minmax(1rem, 7rem)); */
    overflow:auto;
    gap:1rem;
    

  }


  .object{
    background: var(--blue);
    box-shadow: -5px -5px var(--lightblue);
    border: 5px solid var(--darkblue);
    text-align: center;
    display: grid;
    /* align-items: center; */
    justify-content: center;
    /* align-self: center; */
    /* gap: 2rem; */
    transition: all 0.2s linear;
    /* grid-template-rows: repeat(auto-fit,1rem); */

    font-size: xx-large;
    overflow:auto;
    overflow-wrap:anywhere;


    width:15rem;
    height:15rem;

  }

  .entries{
    background: var(--yellow);
    display: grid;
    position:fixed;
    top:3rem;
    /* grid-template-rows: repeat(auto-fit,auto); */
    width: inherit;
    height: inherit;
    overflow:auto;
    gap:1rem;
    font-size:xx-large;

  }

  .subentries{
    display: grid;
    grid-template-columns: repeat(auto-fit,10rem);
    justify-content: center;
    align-items: center;
    gap:1rem;

  }

  .entry{
    background: var(--blue);
    box-shadow: -5px -5px var(--blue);
    border: 5px solid var(--blue);
    text-align: center;
    transition: all 0.2s linear;
    font-size:x-large;
    overflow-wrap:anywhere;
    min-width:fit-content;
    min-height:15rem;

  }


  .fullscreen {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0; 
    position: fixed;
    
  }

  textarea{
    width: 100%;
    height:10rem;
    text-align:center;
    font-size:xx-large;
    overflow-wrap:anywhere;

  }

  .buttons{
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .buttonsshow{
    background: var(--yellow);
    border-radius: 50%;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    height: 2rem;
    width: 2rem;
  }


  .buttons button{
    border-radius: 1rem;
    height: 3rem;
  }

  .selected{
    background: var(--yellow);
  }



</style>
<template>

  <div id="meet"></div>   

  <div class="extra-features">
    <div class="title">Material</div>
    <ClassMaterial/>
  </div>


</template>


<script setup>
  import {provide,reactive,onBeforeMount,onMounted} from 'vue'
  import {useRoute,useRouter} from 'vue-router'
  import {useStore} from 'vuex'
  import axios from 'axios'


  import ClassMaterial from '@/components/ClassMaterial.vue'


  let route=useRoute()
  let router=useRouter()
  let store=useStore()
  let user=store.state.user
  let group_id=''

  let script1=document.createElement('script')

  let data=reactive({})

  let class_material_data=reactive({
    show:false,
    hide:true,
    attendance:null,
    user:null,
  })

  provide('class_material_data',class_material_data)


  async function confirmation(){
    // let response=axios.post('/students/confirm_class/',{userid:store.state.user,groupid:group.id,status:status})
    let response=await axios.post('/students/confirmation/',{user_id:user.id,group_id:group_id,status:'confirmed'})
    console.log(response.data)
  }

  onBeforeMount(()=>{

    if (user==null){
      router.push('/login')
    }else{

      console.log(route.params.link)
      group_id=route.params.link.split('-')[0]




      console.log('Initial group iding:',group_id)
      console.log(user.role)
      switch(user.role){
        case 'Admin':
          break
        case 'Instructor':
          break
        case 'Student':
          confirmation()
          break
        }

      if (isNaN(parseInt(group_id))){
        console.log('Group ID not a number')
      }else{
        get_attendance(group_id)
        setInterval(()=>{
          get_attendance(group_id)
        },1000)
      }


    }

    console.log(route)
    script1.src='https://meet.interactspeakingcenter.com/external_api.js'
    let head=document.getElementsByTagName('head')[0]
    head.append(script1)
    

  })

  onMounted(()=>{

    script1.onload=()=>{

      let link=route.params.link


      const domain = 'meet.interactspeakingcenter.com';
      const options = {
        roomName: link,
        width: '100%',
        height: '100%',
        parentNode: document.querySelector('#meet'),
        userInfo: {
          email: 'email@iasc.com',
          displayName: 'InterAct User'
        }

      };

      
      


      const api = new JitsiMeetExternalAPI(domain, options);

      api.addEventListener('videoConferenceLeft',()=>{

        console.log('videoConferenceLeft: ', user.first_name)
        if (user.role=='Student'){

          router.push('/student-dashboard/')

        }else if (user.role=='Instructor'){
          router.push('/instructor-dashboard/')
        }else{
          router.push('/admin-dashboard/')
        }

      })

      api.addEventListener('readyToClose',()=>{

        console.log('readytoClose: ', user.first_name)

        switch(user.role){
          case 'Admin':
            router.push('/admin-dashboard/')
            break
          case 'Instructor':
            router.push('/instructor-dashboard/')
            break
          case 'Student':
            router.push('/student-dashboard/')
          break
        }

      })



    }  

  })






  async function get_attendance(group_id){
    let response = await axios.post('/meet/get_attendance/',{group_id:parseInt(group_id)})
    class_material_data.attendance=response.data
    data.attendance=response.data
    

    if (data.attendance.video_link!=data.attendance.link){
      window.open(`${data.attendance.video_link}`,'_blank')
      window.location.href='/student-dashboard'
    }
  }







</script>



<style scoped>
  #meet{
    width:100vw;
    height:100vh;
  }

  .extra-features{
    background:var(--white);
    color:var(--black);
    width: 100%;
    height: max-content;
  }

</style>
<template>
  
    <div class="status-buttons" :class="{show:show, hide:hide}">
  
      <button :class="{show_buttons:show, hide_buttons:hide}" @click.stop="confirmation(group,user_id,'confirmed')" class="confirmed">Confirmed</button>
      <button :class="{show_buttons:show, hide_buttons:hide}" @click.stop="confirmation(group,user_id,'cancelled-late')" class="cancelled-late">Cancelled Late</button>
      <button :class="{show_buttons:show, hide_buttons:hide}" @click.stop="confirmation(group,user_id,'no-show')" class="no-show">No Show</button>
      <button :class="{show_buttons:show, hide_buttons:hide}" @click.stop="confirmation(group,user_id,'cancelled-on-time')" class="cancelled-on-time">Cancelled On Time</button>
      <button :class="{show_buttons:show, hide_buttons:hide}" @click.stop="confirmation(group,user_id,'rescheduled')" class="reschedule">Reschedule</button>
    
    </div>
  
  </template>
  
  <script setup>
    import {ref,reactive,inject,onUpdated} from 'vue'
    import axios from 'axios'
    
    
  
    defineProps({
      user_id:null,
      group:null,
      show:null,
      hide:null,
  
    })
  
  
  
  
  
  
  
    async function confirmation(group,user_id,status){
  
      let response= await axios.post('/instructors/confirmation/',{group_id:group.id,user_id:user_id,status:status})
      group=Object.assign(group,response.data)
  
      
  
    }
  
  
  
  
  </script>
  
  
  
  <style scoped>
  
  
  .status-buttons{
    position:relative;
    height:0%;
    width: 0%;
    /* font-size:0px; */
    /* background:var(--black); */
    display:grid;
    align-items:center;
    justify-content: center;
    overflow:hidden;
    align-self:center;
    justify-self:center;
  
    transition: var(--transition);
  
  
    grid-template-areas:"confirmed cancelled-late no-show cancelled-on-time rescheduled ";
  
  }
  
  
  
  
  
  .confirmed{
    background:var(--darkgreen);
    grid-area:confirmed;
  }
  .cancelled-late{
    background:var(--red);
    grid-area:cancelled-late;
  
  }
  .no-show{
    background:var(--darkred);
    grid-area:no-show;
  }
  
  .cancelled-on-time{
    background:var(--lightblue);
    grid-area:cancelled-on-time;
  
  }
  .rescheduled{
    background:var(--gray);
    grid-area:rescheduled;
  
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  .show_buttons{
  
  animation-name: show-buttons;
  animation-duration: var(--animation-time);
  animation-timing-function: ease-in-out;
  /* animation-delay: calc(var(--home)*2); */
  /* animation-iteration-count: iteration-count; */
  animation-direction: normal;
  animation-fill-mode: forwards;
  
  --height_b:30px;
  --width_b:max-content;
  --font-size_b:12px;
  --padding_b:5px;
  --margin_b:0px;
  --gap_b:0px;
  
  }
  
  
  @keyframes show-buttons {
    0%{
      min-width:0%;
      width:0%;
      max-width:0%;
      min-height:0%;
      height:0%;
      max-height:0%;
      font-size:0px;
      padding:0px;
      margin:0px;
      gap:0px;
    }
  
    100%{
      min-width:var(--width_b);
      width:var(--width_b);
      max-width:var(--width_b);
      min-height:var(--height_b);
      height:var(--height)_b ;
      max-height:var(--height_b);
      font-size:var(--font-size_b);
      padding:var(--padding_b);
      margin:var(--margin_b);
      gap:var(--gap_b);
  
      
    }
  
  }
  
  
  
  
  .hide_buttons{
  
  
  animation-name: hide-buttons;
  animation-duration: var(--animation-time);
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-fill-mode: forwards;
  }
  
  
  
  
  @keyframes hide-buttons {
  0%{
    min-width:var(--width_b);
      width:var(--width_b);
      max-width:var(--width_b);
      min-height:var(--height_b);
      height:var(--height)_b ;
      max-height:var(--height_b);
      font-size:var(--font-size_b);
      padding:var(--padding_b);
      margin:var(--margin_b);
      gap:var(--gap_b);
  }
  
  100%{
    min-width:0%;
    width:0%;
    max-width:0%;
    min-height:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
    gap:0px;
  }
  }
  
  
  
  
  
  </style>
<template lang="html">
  <div class="logout">
    You are now logged out.<br>
    Thank you for visiting

  </div>
</template>

<script setup>
  import axios from 'axios'
  import {useStore} from 'vuex'
  import { onMounted } from 'vue';

  let store = useStore()

  onMounted(()=>{
    logout()

  })

  async function logout(){
    try {
      store.commit('removeAuth')
      let response = await axios.post('/auth/token/logout/')

    } catch (error) {
      console.log(error);
    }
  }

</script>

<style lang="css" scoped>

  .logout{
    width: 100vw;
    height: 10rem;
    font-size: 3rem;
    text-align: center;
    color: rgb(99, 6, 6)
  }


</style>

<template>

    <div class="container">

        <div class="timing">
            <input v-model="initial_datetime" type="datetime-local">
            <input v-model="final_datetime" type="datetime-local">
            <button @click.stop="filter">Filter</button>
        </div>
        <div @click.stop="reset" class="bad-attendances">
            <div class="bad-attendance" v-for="attendance in data.bad_attendances">
                <div class="admin-check">
                    <label>
                        Admin Check:
                        <input @change.stop="patch(attendance,'admin_check')" type="checkbox" v-model="attendance.admin_check">

                    </label>
                </div>
                <div class="attendance-datetime">
                    {{ attendance.datetime }}
                </div>
                <div class="attendance-id">
                    {{ attendance.id }}
                </div>
                <div class="attendance-group">
                    <template v-if="attendance.group">
                        {{ attendance.group.subject }} | 
                        <template v-for="instructor in attendance.group.instructor">
                            {{ instructor.ui }}. 
                        </template>
                    </template>
                    <template v-else>
                        No Group
                    </template>
                </div>
                <div class="instructor-confirmations">
                    <template v-for="instructor_confirmation in attendance.instructor_confirmation">
                        {{ instructor_confirmation.instructor.ui }}
                        <select @change.stop="patch(instructor_confirmation,'confirmation')" v-model=instructor_confirmation.confirmation>                           
                            <option v-for="confirmation in confirmations">{{ confirmation }}</option>
                        </select>


                    </template>
                </div>

                <div  class="student-confirmations">
                    <template v-if="attendance.student_confirmation">
                        <template v-for="student_confirmation in attendance.student_confirmation">
                            {{ student_confirmation.student.ui }}
                            <select @change.stop="patch(student_confirmation,'confirmation')" v-model=student_confirmation.confirmation>                           
                                <option v-for="confirmation in confirmations">{{ confirmation }}</option>
                            </select>

                        </template>
                    </template>
                </div>
                <div class="attendance-comments">
                    <template v-if="attendance.comments">
                        Comments:
                        <textarea @change.stop="patch(attendance,'comments')" v-model="attendance.comments">
                            {{ attendance.comments }}

                        </textarea>
                    </template>
                </div>
            </div>
        </div>
    </div>


    <!-- <Payments/> -->

    
    
    
</template>


<script setup>
    import axios from 'axios'
    import Payments from '@/components/Payments.vue'
    import {ref,reactive, onBeforeMount, provide} from 'vue'


    let bad_attendances=reactive({})

    let data=reactive({})


    let datetime=new Date()
    let initial_datetime=ref(datetime.getFullYear(),datetime.getMonth(),datetime.getDate())
    let final_datetime=ref(datetime.getFullYear(),datetime.getMonth(),datetime.getDate())


    let confirmations=ref(['pending','confirmed','cancelled-late','no-show','rescheduled','cancelled-on-time'])


    provide('data',data)



    async function filter(){
        let response=await axios.post('/bad_attendances/filtered_bad_attendances/',{initial_datetime:initial_datetime.value,final_datetime:final_datetime.value})

        data.bad_attendances=response.data
        fix_bad_attendances()

    }


    onBeforeMount(async ()=>{
        let response = await axios.post('/bad_attendances/bad_attendances/')
        data.bad_attendances=response.data

    })





    async function patch(element,key){
        let value=element[key]
        let response=await axios.patch('/payments/patch/',{object:element,key:key,value:value}) 
        Object.assign(element,response.data)
    }



</script>



<style scoped>

    .container{
        width: 100vw;
        background: var(--gray);
        display: grid;
        gap:1rem;
        justify-content: center;
        justify-items:center;
        align-items: center;



    }

    .bad-attendances{
        display: grid;
        grid-template-columns: repeat(auto-fill,300px);
        width: 90vw;
        background: var(--gray);
        gap:1rem;
        justify-content:center;
        align-items: center;


    }

    .bad-attendance{

        background: var(--red);
        color:var(--white);
        border-radius: 1rem;
        border: 5px solid var(--darkred);
        padding:1rem;

        gap:1rem;




        display: grid;
        grid-template-areas:
                    'admin-check'
                    'group'
                    'id'
                    'datetime'
                    'instructor-confirmations' 
                    'student-confirmations';
    }

    .attendance-datetime{
        grid-area: datetime;
    }

    .attendance-group{
        grid-area: group;
    }

    .attendance-id{
        grid-area: id
    }

    .instructor-confirmations{
        grid-area: instructor-confirmations;
        display: flex;
        flex-direction: column;

    }
    .student-confirmations{
        grid-area: student-confirmations;
        display: flex;
        flex-direction: column;
    }

    .admin-check{
        grid-area: admin-check;
    }


</style>
<template>
    <div @click.stop="close_addfromlist_window()" class="addfromlist-container" :class="{show_addfromlist_window:data.show_addfromlist_window,hide_addfromlist_window:data.hide_addfromlist_window}">
        <div class="title">
            Add {{data.children_endpoint}} to {{ data.parent.endpoint }} {{ data.parent.ui }}
        </div>
    
        <div  class="addfromlist">
            <button @click.stop="toggle_select_child(child)" :class="{selected:child.selected}" v-for="child in data.children" class="child">
                {{child.ui}}
            </button>
            
        </div>
    </div>
</template>

<script setup>

import {inject} from 'vue'
import axios from 'axios'

let data=inject('data')

let selected=[]

async function close_addfromlist_window(){

        selected=data.children.filter(child=>child.selected).map(child=>child.id)

        let response=await axios.post('/admin/addfromlist/',{parent:data.parent,children_endpoint:data.children_endpoint,children_ids:selected})
        Object.assign(data.parent,response.data)

        data.hide_addfromlist_window=true
        setTimeout(()=>{
            data.show_addfromlist_window=false
        
        },500)



        setTimeout(() => {
            // data.parent={}
            data.children={}
            
        }, 500);

    }

function toggle_select_child(child){

    if (child.selected){
        child.selected=false
    }else{
        child.selected=true
        selected.push(child.id)
    }

}




</script>

<style scoped>


.addfromlist-container{
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 0;
    height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow: auto;
    background:var(--darkred);
    color:var(--white);
    max-width:0;
    max-height:0;
}



.addfromlist{
    display: flex;
    flex-direction: column;
    gap: 5px;
    widows: 100%;
}




.child{

    background:var(--red);

}

.selected{
    background: var(--green);
    color:var(--black);
}










.show_addfromlist_window{

animation-name: show_addfromlist_window;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */
/* animation-iteration-count: iteration-count; */
animation-direction: normal;
animation-fill-mode: forwards;

--height:80vh;
--width:100%;

--font-size:large;
--padding:5px;
--margin:5px;

}





@keyframes show_addfromlist_window {
  0%{
    width:0%;
    max-width:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
  }

  100%{
    /* min-width:var(--width); */
    width:var(--width);
    max-width:var(--width);
    /* min-height:var(--height); */
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);

    
  }

}




.hide_addfromlist_window{


animation-name: hide_addfromlist_window;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */

animation-direction: normal;
animation-fill-mode: forwards;
}




@keyframes hide_addfromlist_window {
  0%{
    width:var(--width);
    max-width:var(--width);
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);
  }

  100%{
    width:0%;
    max-width:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
  }



  
}


</style>

<template>
	<div>
		MATERIAL 1
    <Introduction ref="introduction"/>

    <div class="headers">
      <div>English</div>
      <div>Pronunciation</div>
      <div>Spanish</div>
    </div>

    <div @click.stop="show(greeting)"  class="greeting" v-for="greeting in greetings">
      <div class="english">{{greeting.english}}</div>
      <div class="pronunciation" v-show="greeting.show">{{  greeting.pronunciation }}</div>
      <div class="spanish" v-show="greeting.show"> {{ greeting.spanish }}</div>
    </div>


	</div>
</template>


<script setup>
  import Introduction from '@/components/material/introduction.vue'
  import {ref,onBeforeMount} from 'vue'

  import { useStore } from 'vuex';

  import axios from 'axios';


  let store=useStore()


  let material=ref()
  let introduction=ref()
  

  let greetings=ref(
    [
    {
      "english": "Hello",
      "pronunciation": "həˈloʊ",
      "spanish": "Hola",
      "show": false
    },
    {
      "english": "Hi",
      "pronunciation": "haɪ",
      "spanish": "Hola",
      "show": false
    },
    {
      "english": "Good morning",
      "pronunciation": "ɡʊd ˈmɔrnɪŋ",
      "spanish": "Buenos días",
      "show": false
    },
    {
      "english": "Good afternoon",
      "pronunciation": "ɡʊd ˌæftərˈnun",
      "spanish": "Buenas tardes",
      "show": false
    },
    {
      "english": "Good evening",
      "pronunciation": "ɡʊd ˈivnɪŋ",
      "spanish": "Buenas noches",
      "show": false
    },
    {
      "english": "Hey",
      "pronunciation": "heɪ",
      "spanish": "Hola",
      "show": false
    },
    {
      "english": "Howdy",
      "pronunciation": "ˈhaʊdi",
      "spanish": "Hola",
      "show": false
    },
    {
      "english": "Welcome",
      "pronunciation": "ˈwɛlkəm",
      "spanish": "Bienvenido/a",
      "show": false
    },
    {
      "english": "Good to see you",
      "pronunciation": "ɡʊd tuː siː jʊ",
      "spanish": "Qué gusto verte",
      "show": false
    },
    {
      "english": "How are you?",
      "pronunciation": "haʊ ɑːr jʊ",
      "spanish": "¿Cómo estás?",
      "show": false
    },
    {
      "english": "What's up?",
      "pronunciation": "wɑːts ʌp",
      "spanish": "¿Qué tal?",
      "show": false
    },
    {
      "english": "Good to meet you",
      "pronunciation": "ɡʊd tuː miːt jʊ",
      "spanish": "Gusto en conocerte",
      "show": false
    },
    {
      "english": "Pleased to meet you",
      "pronunciation": "pliːzd tuː miːt jʊ",
      "spanish": "Mucho gusto",
      "show": false
    },
    {
      "english": "How's it going?",
      "pronunciation": "haʊz ɪt ˈɡoʊɪŋ",
      "spanish": "¿Cómo te va?",
      "show": false
    },
]



  )


  onBeforeMount(async ()=>{
    let materialid=1
    // let response=await axios.post('/materials/get_material/',{materialid:materialid})

    material.value=response.data

    store.dispatch('get_introduction',{material:material,introduction:introduction})
  })




  function show(greeting){
    greeting.show=!greeting.show
  }



</script>


<style scoped>

.headers{
    display: flex;
    border: 3px solid black;
    gap: 1rem;
    margin: 1rem;
    justify-content: space-around;
  }

  .greeting{
    display: flex;
    border: 3px solid black;
    gap: 1rem;
    margin: 1rem;
    align-items: center;
    justify-content: space-around;
    height: 4rem;
    font-size: 1.5rem;
    

  }


</style>


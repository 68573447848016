<template>
    <div class="container">
        <label>
            Delete {{ object.endpoint }} {{ object.ui }}
            <button @click.stop="delete_object()">
                OK
            </button>
        </label>
    </div>
</template>

<script setup>


    import {reactive,ref, inject} from 'vue'
    import axios from 'axios'

    let object=inject('delete_object')

</script>

<style scoped>
    .container{
        position:fixed;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        width: 0;
        height: 0;
        overflow: hidden;
    }

</style>


<template>

<div class="section meeting">
  <label>Meeting Name: 
    <input v-model="meeting_name">
    <button @click.stop="create_meeting(meeting_name)">Create Meeting</button>
  </label>
</div>

<div class="section cards">
    <Card ref="students"/>  
    <Card ref="instructors"/>  
    <Card ref="groups"/>  
    <router-link to="/bad-attendances">
      <Card ref="bad_attendances"/>  
    </router-link>

  </div>



  <div class="section">
    <Tickets/>
  </div>


  <div class="section">
    <Collection/>
  </div>

  <div class="section">
    <Instructors/>
  </div>

  <div class="section">
    <AttendanceReport/>
  </div>





    
  <div class="section">

    <InitialMessage/>
  </div>
    


<router-link to="/database">Database</router-link>



</template>

<script setup>
  import {ref, onBeforeMount, reactive} from 'vue'
  import axios from 'axios'
  import Card from '@/components/DashboardCard.vue'

  import Instructors from '@/components/Instructors.vue'

  import InitialMessage from '@/components/InitialMessage.vue'

  import Collection from '@/components/Collection.vue'
  import Tickets from '@/components/Tickets.vue'
  import AttendanceReport from '@/components/AttendanceReport.vue'


    let students=ref()
    let instructors=ref()
    let groups=ref()
    let bad_attendances=ref()
    let meeting_name=ref()
    let collection=reactive({})

    function create_meeting(meeting_name){
      console.log('testing meeting',meeting_name)
      window.location.href=`/meet/${meeting_name}`
    }



  onBeforeMount(async()=>{
    let response=await axios.post('/dashboard/get_cards/')
    let data=response.data
    
    students.value.object={
                                title:'Students',
                                total:data.students

                              }

    instructors.value.object={
                                title:'Instructors',
                                total:data.instructors

                              }

    groups.value.object={
                                title:'Groups',
                                total:data.groups

                              }

    bad_attendances.value.object={
                                title:'Bad Attendances',
                                total:data.bad_attendances

                              }
    


    
      

  })
  



</script>



<style scoped>


  .meeting{
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cards{
    display: grid;
    grid-template-columns: repeat(auto-fill,20rem);
    gap: 1rem;
    justify-items: center;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background:var(--gray);
  }



  .section{
    width:100vw;
    background:var(--blue);
    color:var(--white);
    overflow: hidden;

  }

  .section:nth-child(2n){
    background:var(--red);
  }



</style>
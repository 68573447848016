<template>
  
  <div class="screen initial-screen-animation" ref="screen">

  </div>



  <div class="initial-animation">
    <div class="quote">
      "El aprendizaje es experiencia, todo lo demás es información." 
    </div>
    <div class="author">
      A. Einstein.
    </div>
  </div>
  
  <div class="secondary-animation">

    <img class="logo" :src="logo">
    <div class="logo-text">
      InterAct Speaking Center
    </div>

  </div>



</template>









<script setup>

import {ref,onBeforeMount} from 'vue'

  let screen=ref()
  let logo=ref()
  onBeforeMount(()=>{
    logo.value=require('@/assets/logo.png')

    // startAnimation()


  })
      
  // function startAnimation(){
  //   screen.value.classList.add('initial-animation')


  // }

</script>



<style scoped>

  .screen{
    position:absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height:0;
    width:0;

    border-radius:50%;

    background: var(--red);

    overflow:hidden;
    
    z-index:-10

  }



  .initial-screen-animation{
    animation-name:initial-screen-animation;
    animation-duration: calc(var(--home)*2);
    animation-timing-function: ease-in-out;
    /* animation-delay: 0s; */
    /* animation-iteration-count: iteration-count; */
    animation-direction: normal;
    animation-fill-mode: forwards;

    
  }






  @keyframes initial-screen-animation{
    
    

    10%{
      height: max(100vw,100vh);
      width: max(100vw,100vh);
      border-radius:0%;
    }

    97%{
      height: max(100vw,100vh);
      width: max(100vw,100vh);
      border-radius:0%;
      opacity:1;
    }
    100%{
      border-radius:50%;
      height:0;
      width:0;
      opacity:0;
    }

  }



  .quote{
    font-size:xxx-large;
    font-family: var(--bebas);
    text-align: center;
    color:var(--white);


  }


  .author{
    font-size:x-large;
    font-family:var(--satisfy);
    text-align:center;
    color:var(--white);
  }


  .initial-animation{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    opacity:0;


    animation-name:initial-animation;
    animation-duration: calc(var(--home));
    animation-timing-function: ease-in-out;
    /* animation-delay: 0s; */
    /* animation-iteration-count: iteration-count; */
    animation-direction: normal;
    animation-fill-mode: forwards;

    z-index:-10

  }

  @keyframes initial-animation{
    10%{
      opacity:1;
    }




    90%{
      opacity:1;
    }

    100%{
      opacity:0;

    }

  }




  .secondary-animation{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    opacity:0;


    animation-name:secondary-animation;
    animation-duration: var(--home);
    animation-timing-function: ease-in-out;
    animation-delay: var(--home);
    /* animation-iteration-count: iteration-count; */
    animation-direction: normal;
    animation-fill-mode: forwards;

    z-index:-10


  }


  .logo{
    height:20rem;
    width: 20rem;
    /* min-width:fit-content; */
    max-width: 20rem;
  }


  .logo-text{

    color:var(--white);
    text-align: center;
    font-family:var(--passion);
    font-size: xx-large;
  }

  
  @keyframes secondary-animation{

    10%{
      opacity:1;
    }
    

    90%{
      opacity:1;
    }


    100%{
      opacity:0;
    }
  }


</style>

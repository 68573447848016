<template>
    <div class="container">

        <div class="title">
            Cancelación de clase
        </div>


        <div class="form-container">
            <form class="cancellation-form">
                <div class="subtitle">
                    {{ data.user.ui }}
                </div>
                <label>
                    Motivo de Cancelación:
                </label>
                <textarea v-model="data.reason" placeholder="…Motivo de Cancelación…">

                </textarea>

            </form>
            <hr>
            <button @click.stop="cancel_class()">Cancelar Clase</button>

        </div>





    
        <div class="cancellation-policy">
            <div class="policy-warning subtitle">
                Politica de Cancelación:
            </div>
            <ul>
                <li>
                    Cancelación a tiempo:
                    <ul>
                        <li>
                            Se considerara cancelación a tiempo cuando el profesor haya mandado la confirmación 2 hrs antes de la clase programada y el alumno haya cancelado con más de 1hr de anticipación.
                        </li>
                    </ul>
                    Cancelación Tardía:
                    <ul>
                        <li>
                            Se considerara cancelación a tardía cuando el profesor haya mandado la confirmación 2 hrs antes de la clase programada y el alumno haya cancelado con menos de 1hr de anticipación.
                        </li>
                    </ul>
                    Notas Adicionales:
                    <ul>
                        <li>
                            En caso de que el profesor haya mandado la confirmación menos de 2 hrs antes de la clase programada cualquier cancelación será considerada como cancelación a tiempo.
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>



<script setup>
    import {onBeforeMount,onMounted,reactive,inject} from 'vue'
    import axios from 'axios'
    import {useStore} from 'vuex'
    import {useRoute} from 'vue-router'

    let store=useStore()
    let route=useRoute()

    let user=store.state.user

    let data=reactive({
        reason:''
    })

    data.user=user


    onBeforeMount(async ()=>{

        if (user.role.includes('Admin')){
            let user_id=route.query.user_id
            
            let response= await axios.post('/admin/get_user/',{user_id:user_id})
            user=response.data
            data.user=user
            
        }
        data.group_id=route.query.group_id
    })



    async function cancel_class(){


        if (data.reason==''){
            alert('Informacion faltante: motivo de cancelación')
            return
        }
        // group id is not defined. define in url query?
        let response=await axios.post('/students/cancellation/',{user_id:data.user.id,group_id:data.group_id,status:'cancelled-by-student'})
        let status=response.data.status
        alert(`La clase ha sido cancelada con el siguiente estatus: ${status.toUpperCase()}`)
        window.location.href=`/student-dashboard?user_id=${user.id}`
    }



</script>



<style scoped>

    .container{

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background: var(--darkred);
        min-width: 100vw;
        min-height: 100vh;
        max-width: 100vw;
        max-height: 100vh;
        
    }

    .form-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        min-width: max-content;
        width: fit-content;
        max-width: 100%;

        border-radius: 5px;
        border: var(--border);

        background: var(--red);

        padding: 1rem;
    }

    .cancellation-form{

        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;

        min-width: max-content;
        width: max-content;
        max-width: 100%;

        gap: 15px 0px;
    }

    .cancellation-form label {
        text-align: end;

    }


    hr {
        width: 100%;
        margin: 1rem;
    }

    
    textarea{
        width: 25rem;
        height: 15rem;
    }


    .cancellation-policy{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }




</style>
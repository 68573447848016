<template>
    <div class="edit-subobject-container" :class="{show_edit_subobject_window:data.show_edit_subobject_window,hide_edit_subobject_window:data.hide_edit_subobject_window}">
        <div @click.stop="close_edit_window()" class="title">{{data.edit_subobject.endpoint}} {{ data.edit_subobject.ui }}</div>
        <div @click.stop class="edit">
            <template v-if="data.edit_subobject">
                <table>
                    <tbody>
                        <template v-for="(value,key,index) in data.edit_subobject">
                                <tr>
                                    <td>
                                        {{ key }}:
                                    </td>
                                    <template v-if="typeof(value)=='object'">
                                        <td>
                                            <button @click.stop="show_edit_subobject_window(data.edit_subobject[key])">
                                                Edit {{ key }}
                                            </button>
                                        </td>
                                    </template>
                                    <template v-else-if="typeof(data.edit_subobject[key])=='boolean'">
                                        <td>
                                            <input @change.stop="patch(data.edit_subobject,key,data.edit_subobject[key])" v-model="data.edit_subobject[key]" type="checkbox">
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td>
                                            <input @change.stop="patch(data.edit_subobject,key,data.edit_subobject[key])" v-model="data.edit_subobject[key]">
                                        </td>
                                    </template>
                                </tr>

                        </template>

                    </tbody>
                </table>
                
            </template>
        </div>
    </div>
</template>



<script setup>

    import {reactive,ref, inject} from 'vue'
    import axios from 'axios'

    let data=inject('data')
    
    function close_edit_window(){
        data.hide_edit_subobject_window=true
        setTimeout(()=>{
            data.show_edit_subobject_window=false
        
        },500)



        setTimeout(() => {
            data.edit_subobject={}
            
        }, 500);

    }



    function patch(object,key,value){
        let response=axios.patch('/admin/patch/',{object:object,key:key,value:value})
        console.log(response.data)
        Object.assign(object,response.data)
    }



</script>


<style scoped>


.edit-subobject-container{
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 0;
    height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow: auto;
    background:var(--lightred);
    color:var(--white);
    max-width:0;
    max-height:0;
}


.edit{
    display: flex;
    flex-direction: column;
    gap: 5px;
    widows: 100%;
}











.show_edit_subobject_window{

animation-name: show_edit_subobject_window;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */
/* animation-iteration-count: iteration-count; */
animation-direction: normal;
animation-fill-mode: forwards;

--height:80vh;
--width:100%;

--font-size:large;
--padding:5px;
--margin:5px;

}





@keyframes show_edit_subobject_window {
  0%{
    width:0%;
    max-width:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
  }

  100%{
    /* min-width:var(--width); */
    width:var(--width);
    max-width:var(--width);
    /* min-height:var(--height); */
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);

    
  }

}




.hide_edit_subobject_window{


animation-name: hide_edit_subobject_window;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */

animation-direction: normal;
animation-fill-mode: forwards;
}




@keyframes hide_edit_subobject_window {
  0%{
    width:var(--width);
    max-width:var(--width);
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);
  }

  100%{
    width:0%;
    max-width:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
  }



  
}





</style>



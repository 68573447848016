<template>
    <div class="title">
        {{user.first_name}}'s Calendar
    </div>
    <div class="calendar">
        <iframe ref="iframe" style="border-width:0" width="800" height="600" frameborder="0" scrolling="no"></iframe>


    </div>
</template>

<script setup>
    import {ref,reactive, inject, onMounted,onBeforeMount} from 'vue'
    import axios from 'axios'


    let props=defineProps({
        user:{},
    })

    let iframe=ref()

    onMounted( ()=>{

        setTimeout(async () =>{
            get_calendar()

        },1000)

    })


    async function get_calendar(){
        let user=props.user
            let response=await axios.post('/instructors/get_calendar/',{user_id:user.id})
            let calendar_id=response.data.calendar
            let source=`https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FMexico_City&showTz=0&showCalendars=0&showTabs=0&showPrint=0&showDate=0&showNav=0&showTitle=0&mode=WEEK&src=${calendar_id}&color=%234285F4`
            
            iframe.value.src=source
    }

</script>

<style scoped>
    .calendar{
        background:var(--white);
        width:100vw;
        height: 100%;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    iframe{
        border-radius:1rem;
        filter: invert(.9) saturate(0.5) hue-rotate(145deg);
    
    }


</style>
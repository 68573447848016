<template>
  
  <!-- <video class="login-video"> -->
    <img class='login-video' :src="mountains">
  <!-- </video> -->
  
  <div class="login-container">
    <img class="logo" src='@/assets/logo.png'>
    <form @submit.prevent='login' class="login" method="post">
      <input name="username" type="text" placeholder="InterAct username">
      <input name="password" type="password" placeholder="Password">
      <button type="submit" name="button">Login</button>
    </form>

    <div>
      Reestablecer Contraseña | Reset Password
    </div>

  </div>


</template>

<script setup>
import { functionTypeAnnotation } from '@babel/types';
import axios from 'axios'
import {ref, onBeforeMount} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'




let store=useStore()
let router = useRouter()

let mountains=require('@/assets/login/scenery.jpg')


let user

onBeforeMount(()=>{
  let store_user=store.state.user
  dashboard_redirect(store_user)

})



async function login(e){
  let formdata=new FormData(e.target)
  try {


    let response = await axios.post('/auth/token/login/',formdata)


    const token = response.data.auth_token
    axios.defaults.headers.common['Authorization'] = 'Token '+token


    response=await axios.get('/auth/users/me/')
    user=response.data


    await store.commit('removeAuth')
    console.log(token);
    await store.commit('setAuth',{user:user,token:token})

    dashboard_redirect(user)

  } catch (error) {
    let response=error.response
    if (response.status==400){
      alert('Login is incorrect')
    }

  }


}



  function dashboard_redirect(user){

    if (user.role == 'Admin'){
      router.push('/admin-dashboard/')

    }else if(user.role == 'Student'){
      router.push('/student-dashboard/')

    }
    else if(user.role == 'Instructor'){
      router.push('/instructor-dashboard/')

    }

  }

</script>





<style scoped>



  .login-video{
    z-index: -10;
    position:fixed;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    filter:blur(10px);
  }
  .login-container{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100vw;
    height:100vh;
  }

  .logo{
    width:10rem;
  }
  .login{
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* height: 20rem; */
    justify-content: center;
    align-items: center;
  }


  input{
    margin: 1rem;
  }
</style>

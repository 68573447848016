<template>

  <div class="status-time">
    <input v-model="data.time" type="time" step="any">
  </div>


</template>

<script setup>
  import {ref,reactive,inject,onUpdated} from 'vue'

  import axios from 'axios'

  let data=reactive(
    {
      time:'',
    }
  )
  data=inject('time_data')



</script>



<style scoped>





.status-time{

  display:flex;
  align-items:center;
  justify-content: center;
  overflow:hidden;
  transition: var(--transition);

  z-index: 10;
  /* grid-template-areas:"confirmed cancelled-late no-show cancelled-on-time rescheduled "; */

}




















</style>
<template>
  <!-- <div class="container-status-window" :class="{hide_confirmation_window:data.hide,show_confirmation_window:data.show}"> -->
  <div class="container-status-window" :class="[{hide_confirmation_window:data.hide,show_confirmation_window:data.show}, { [data.status]: true }]">

    <div @click.stop="close_window()" class="title">
      {{ data.group.ui }}
    </div>
    <div class="subtitle">
      Confirmation Window
    </div>

    <div class="confirmation">
      <label>
        Select class status:
      </label>
      <StatusButtons/>
      <hr>

      <label>
        Select Class Time:
      </label>
      <Time />
      <hr>
      
      <label>
        Select Duration:
      </label>
      <Duration/>
      <hr>
      <template v-if="data.status=='confirmed'">
        <label>
          Select VideoLink:
        </label>
        <VideoLink/>
        <hr>
      </template>
      <div class="subtitle">
        Summary:
      </div>
      <div class="summary">
        <label>
          Status: {{ data.status }}
        </label>
        <label>
          Instructor: {{ data.user.first_name }} {{ data.user.last_name }}
        </label>
        <label>
          Students: 
          <template v-for="student in data.group.student">
            {{ student.ui }}. 
          </template>
        </label>
        <label>
          Time (24hrs): {{ time_data.time }}
        </label>
        <label>
          Duration: {{ duration_data.duration }} hrs
        </label>
        <label>
          Link: {{ video_link_data.link }}
        </label>
      </div>
      
      <button @click.stop="send_whatsapp_status()">Send Confirmation/Send Whatsapp message to student</button>
    </div>
    
    


  </div>


  <!-- <SelectMaterial/> -->
</template>

<script setup>
  import {ref,reactive,inject,provide,onUpdated} from 'vue'
  import SelectMaterial from '@/components/SelectMaterial.vue'
  import StatusButtons from '@/components/StatusButtons.vue'
  import Time from '@/components/StatusTime.vue'
  import Duration from '@/components/StatusDuration.vue'
  import VideoLink from '@/components/StatusVideoLink.vue'


  import axios from 'axios'




  let time_data=reactive({time:''})

  time_data=inject('time_data')
  
  let duration_data=reactive({
    duration:0,
  })

  duration_data=inject('duration_data')


  let video_link_data=inject('video_link_data')




  let data=reactive({
    group:{},
    user:{id:0},
  })

  data=inject('status_data')



  async function send_whatsapp_status(){

    let students=data.group.student.map(student=>{
      return student.id

    })

    let instructor=data.user.ui

    let time=time_data.time
    if (time){
      time=time.split(':')
      if (time[0]>12){
        time[0]=time[0]-12
        time=`${time[0]}:${time[1]} PM`
      }else if(time[0]==12){
          time=`${time[0]}:${time[1]} PM`
      }else{
        time=`${time[0]}:${time[1]} AM`
      }
    }

    let duration=duration_data.duration

    let link=video_link_data.link


    let parameters={}
    if(data.status=='confirmed'){
      parameters={
        user_id:data.user.id,
        group_id:data.group.id,
        status:data.status,
        students:students,
        instructor:instructor,
        time:time,
        duration:duration,
        video_link:link
      }
    }else{
      parameters={
        user_id:data.user.id,
        group_id:data.group.id,
        status:data.status,
        students:students,
        instructor:instructor,
        time:time,
        duration:duration,
        video_link:"https://interactspeakingcenter.com/login"
      }

    }




    let wrong_fields=[]
    for(const key in parameters){
      if(!parameters[key]){
        wrong_fields.push(key)
      }
    }
    
    if (wrong_fields.length>0){
      wrong_fields=wrong_fields.join(', ')
      alert(`Please fill the following fields: ${wrong_fields}`)
      return
    }

    let response = await axios.post('/instructors/send_whatsapp_status/',parameters)

    response=response.data.twilio

    let twilio_continue_statuses=[
                            "sent",
                            "delivered",
                            "accepted",
                            "read",
                        ]

    if(twilio_continue_statuses.indexOf(response)>-1){
      alert(`Message: ${response.toUpperCase()}` )
      close_window()
    }else{
      alert('Message Error: PLEASE TRY AGAIN!')
    }



  }



  function close_window(){
    setTimeout(() => {

      data.status=null
      data.group=null
      duration_data.duration=null
      video_link_data.link=null
      time_data.time=null


      data.show=false
      
    }, 500);
    data.hide=true
  }
  


</script>



<style scoped>





.container-status-window{
  position:fixed;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
  /* height:0;
  width:0; */
  /* font-size:0px; */
  background: var(--black);
  color:var(--white);
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content: start;
  align-self:center;
  justify-self:center;
  text-align: center;
  
  
  overflow-y:auto;
  transition: var(--transition);

  z-index: -10;
  /* grid-template-areas:"confirmed cancelled-late no-show cancelled-on-time rescheduled "; */

}


@media (max-width:900px){

  .container-status-window{
    align-items: start; 
  }

}


.confirmation{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 15px;
  width: max-content;
}



label{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

hr{
  width: 100%;
  height: 5px;
  background: var(--white);
}



.summary{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 5px;
}


button{
  min-height: 50px;
  font-size: larger;
  background: var(--darkgreen);
}



.confirmed{
    background:var(--green);
  }
  .cancelled-late{
    background:var(--red);
  }

  .no-show{
    background:var(--darkred);
  }

  .cancelled-on-time{
    background: var(--lightblue);
  }

  .rescheduled{
    background: var(--gray);
  }






/* ########################################## HIDE/SHOW ANIMATIONS ######################################### */



.show_confirmation_window{

animation-name: show_confirmation_window;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */
/* animation-iteration-count: iteration-count; */
animation-direction: normal;
animation-fill-mode: forwards;
max-width:100%;
max-height:80vh;
--height:80%;
--width:100%;

--font-size:large;
--padding:0px;
--margin:0px;
--gap:0px;
z-index: 10;
overflow:auto;
}





@keyframes show_confirmation_window {
  0%{
    min-width:0%;
    width:0%;
    max-width:0%;
    min-height:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
    gap:0px;
  }

  100%{
    min-width:var(--width);
    width:var(--width);
    max-width:var(--width);
    min-height:var(--height);
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);
    gap:var(--gap);

    
  }

}




.hide_confirmation_window{


animation-name: hide_confirmation_window;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */

animation-direction: normal;
animation-fill-mode: forwards;
}




@keyframes hide_confirmation_window {
  0%{
    min-width:var(--width);
    width:var(--width);
    max-width:var(--width);
    min-height:var(--height);
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);
    gap:var(--gap);
  }

  100%{
    min-width:0%;
    width:0%;
    max-width:0%;
    min-height:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
    gap:0px;
  }



  
}















</style>
<template>
    <div class="schedule_change_request_container" :class="{show:data.show, hide:!data.show}">
        <template v-if=data.group>

            <div @click.stop="close_schedule_change()" class="title">
                Request Schedule Change
            </div>
            <div class="group">{{ data.group.ui }}</div>
            <div class="original-schedule">
                <label>
                    Original Schedule:
                    <div>
                        {{ data.group.schedule }}
                    </div>
                </label>
            </div>
            <div class="notes">Note: All times are in Mexico City timezone</div>
            <div class="scheduler">
                <button @click.stop="add_schedule()" class="add">+ add schedule</button>
                <div class="schedule" v-for="new_schedule in data.new_schedules">
                    <div class="days">
                        <div @click.stop="toggle_day(new_schedule,day)" class="day" :class="{selected:new_schedule.days.includes(day)}" v-for="day in days">
                            {{ day }}
                        </div>
                    </div>
                    <div class="start-time">
                        <input v-model="new_schedule.start_time" type="time" class="time">

                    </div>
                    <div class="end-time">
                        <input v-model="new_schedule.end_time" type="time" class="time">
                    </div>

                    <button @click.stop="remove_schedule(new_schedule)" class="close">X</button>
    
                </div>
            </div>
            <div class="confirm-button">
                <button v-if="data.new_schedules.length > 0" @click.stop="request_schedule_change()">Request Schedule Change</button>
            </div>

        </template>

    </div>

</template>




<script setup>
    import {inject,reactive,ref} from 'vue'
    import axios from 'axios'


    let days=ref([
        'MO',
        'TU',
        'WE',
        'TH',
        'FR',
        'SA',
        'SU'
    ])


    let data=inject('request_schedule_change_data')

    // async function schedule_change_request_container(){

    // }



    function add_schedule(){
        data.new_schedules.push({days:[],time:[]})
    }

    function remove_schedule(schedule){
        data.new_schedules=data.new_schedules.filter(s=>s!=schedule)
    }

    function toggle_day(schedule,day){
        

        if (!schedule.days.includes(day)){
            schedule.days.push(day)
            return
        }

        schedule.days=schedule.days.filter(d=>d!=day)
    }


    function close_schedule_change(){

        
        data.show=false
        data.group={ui:''}
        data.new_schedules=[]
    }


    function request_schedule_change(){
        let empties=['',null,[],{},0]
        let wrong=false
        data.new_schedules.forEach(s=>{
            console.log('Schedule',s)
            if (s.days.length==0 || empties.includes(s.time) || !("start_time" in s) || !("end_time" in s)) {
                wrong=true      
            }
        })
        if (wrong){
            alert('Missing days or time in one of the schedules... Please verify and retry again')
            return
        }

        data.final_schedule=data.new_schedules.map(s=>{
            s.days=s.days.join(' ')
            s.final=[s.days,s.start_time,s.end_time].join(' ')
            console.log(s.final)
            return s.final
        })

        data.final_schedule=data.final_schedule.join(' | ')

        let response=axios.post('/tickets/create_schedule_ticket/',{user_id:data.user.id,group_id:data.group.id,proposed_schedule:data.final_schedule})
        
        alert('Request for Schedule change sent successfully!')
        close_schedule_change()
        
    }



</script>


<style scoped>
.schedule_change_request_container{
    position:fixed;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    background:var(--darkyellow);
    color:var(--black);
    display: grid;
    grid-template-areas: "title"
                        "group"
                        "notes"
                        "original-schedule"
                        "scheduler"
                        "confirm-button";

    grid-template-rows: 1fr 1fr 1fr 3fr 1fr;

    justify-content: center;
    align-items: center;
    border:var(--border);
    border-radius: 10px;

    height:0;
    width:0;

    overflow:auto;

    text-align: center;

    gap:20px;
    
    
}

.notes{
    grid-area: notes;
}

.original-schedule{
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    max-width: 100vw;
    grid-area: original-schedule;
}

.group{
    grid-area:group;
}

.scheduler{
    display: flex;
    flex-direction: column;
    gap:30px;
    width:100%;
    max-width: 100vw;
    justify-content: center;
    align-items: center;
    grid-area:"scheduler";
}

@media (max-width:650px){
    .schedule{
        align-self: start;
    }
}




.schedule{
    display:flex;
    justify-content:center;
    align-items:center;
    gap:10px;
    

}

.confirm-button{
    grid-area: confirm-button;
}

.title{
    cursor: pointer;
}

.days{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width: fit-content;
    position: relative;
}

.day{
    font-size:xx-large;
    padding: 5px;
    width: 60px;
    cursor: pointer;
}

.days .day:not(:last-child){
    border-right: var(--border);
}

.days::before{
    position: absolute;
    top:-20px;

    content: "Days";
    display: block;
    text-align: center;
    font-weight: bold;
    color: var(--black); 
}

.time{
    width: 80px;
}


.start-time,.end-time{
    position: relative;
}

.start-time::before{
    position: absolute;
    top:-20px;
    
    content:"Start Time";
    display: block;
    text-align: center;
    font-weight: bold;
    color: var(--black);

}

.end-time::before{
    position: absolute;
    top:-20px;
    
    content:"End Time";
    display: block;
    text-align: center;
    font-weight: bold;
    color: var(--black);

}


.selected{
    background: var(--green);
}

.add{
    font-size: xx-large;
}

.close{
    background: var(--lightred);
    color:var(--white);
    font-weight: bold;
    width: 20px;
    height: 20px;
    text-align: center;
    border: var(--border);
}

</style>
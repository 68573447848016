<template>
    <div class="add-container" v-show="show">
    
      <div class="models" >
        <div @click.stop="toggleselected(model)" class="model" :class="{selected:model.id in selected}" v-for="model in models">
          {{ model.id }} | {{ model.ui }} 
    
    
        </div>
      </div>
      <div class="buttons">
        <button @click.stop="addfromlist">
          Add
        </button>

      </div>
    
    
    </div>
  
  
    
  </template>
  
  
  <script setup>
  
    import axios from 'axios';
  import {ref} from 'vue'
    
  
    let selected=ref({})
    let models=ref({})
    let show=ref(false)
    let parent=ref(null)
    let selectedmodelstring=ref()
    let basemodels=ref(null)
  
  
    function toggleselected(parent){
      if (parent.id in selected.value){
        delete selected.value[parent.id]
        return
      }
  
      selected.value[parent.id]=parent
  
    }
  

    async function addfromlist(){
        console.log(selectedmodelstring.value);
        let response=await axios.post('/database/addfromlist/',{selected:selected.value,selectedmodelstring:selectedmodelstring.value,parent:parent.value,parentmodelstring:parent.value.endpoint})
        basemodels.value=response.data
        models.value={}
        selected.value={}
        parent.value=null
        
        show.value=false
        selectedmodelstring.value=null
        // basemodels.value=null
    }

  
  
  
  
    defineExpose({
      models,
      show,
      parent,
      selectedmodelstring,
      basemodels,
    })
  
  
  
  
  
  
  
  </script>
  
  
  <style scoped>
  
    .add-container{
      position: absolute;
      display: grid;
      width: 50vw;
      height: 50vh;
      top:50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background: var(--secondary);
      justify-content: center;
      align-items: center;
      grid-template-columns: 10fr 1fr;
      /* overflow: auto; */
  
    }
  
    .models{
      display: grid;
      justify-content: center;
      align-items: center;
      grid-area: 1/1/100/2;
      gap: 1rem;
      height:100%;
      overflow:auto;
  
  
    }
  
    .model{
      background: var(--primary);
      border: 5px solid black;
      box-shadow: -5px -5px black;
      text-align: center;
      transition: all 0.2s linear;
      width: 5rem;
      height: 5rem;
    }
  
    .buttons{
      position:sticky;
      grid-area: 2/2;
    }
  
    .buttons button{
      border-radius: 1rem;
      height: 3rem;
    }
  
    .selected{
      background: var(--selected);
    }
  
  
  </style>
<template lang="html">
  <div class="error">
    <li v-for="error in errors">{{error[0]}}: {{error[1][0]}}</li>
  </div>

</template>

<script setup>
import {ref,computed} from 'vue'
import {useStore} from 'vuex'
  let store = useStore()
  let errors=computed(()=>{

    return store.state.errors

  }

)



</script>

<style lang="css" scoped>
  .error{
    width: 100vw;
    background: rgb(84, 0, 15);
    color:white;
    text-align: center;
  }

  li{
    list-style-type: none;
  }

</style>

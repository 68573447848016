
<template>

    <div class="material-container">
        
        <div class="upload-container" >
            <button @click.stop="toggle_upload_material()">Upload Class Material</button>
            <label class="upload-input-container" :class="{show_upload:data.show, hide_upload:data.hide}">
                Select file:
                <input ref="file_input" type="file">
                <button @click.stop="upload_material($event)">Upload File</button>
            </label>
        </div>
    
        <div class="download-container">
            <template v-if="data.attendance">
                <template v-if="data.attendance.material">
                    <template v-for="material in data.attendance.material">
                        <div class="material">
                            {{ material.name }}
                            <button @click.stop="download_material(material)">
                                Download
                              </button>
                              <button class="delete-material">
                                <span @click.stop="delete_material(material)" class="material-symbols-outlined">
                                  delete
                                </span>
                              </button>
                        </div>
                      </template>
                </template>
            </template>
        </div>

    </div>





</template>

<script setup>
    import {inject,ref} from 'vue'
    import axios from 'axios'
    import {useStore} from 'vuex'

    let store = useStore()
    let user = ref(store.state.user)
    let file_input=ref(null)

    let data=inject('class_material_data')

    async function upload_material(){

        if(!data.attendance){
          console.log("Can't upload material to non Attendance Meeting")
          return
        }

        let formData=new FormData()

        console.log(file_input)
        console.log(file_input.value)
        console.log(file_input.value.files)
        console.log(file_input.value.files[0].name)
        console.log('Attendancing:',data.attendance)
        let file=file_input.value.files[0]
        formData.append('file',file)
        formData.append('attendance_id',data.attendance.id)
        
        let response = await axios.post('/meet/upload_material/',formData)
        console.log(response.data)

        
    }

    async function download_material(material){

        let response=await axios.post('/meet/download_material/',{material_id:material.id},{responseType: "blob"})
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', material.name);
        document.body.appendChild(link);
        link.click();
        link.remove();

    }

    function toggle_upload_material(){
    console.log('checking class material status')

    if (data.show){
      setTimeout(()=>{
        data.show=false
        
      },500)
      data.hide=true
    }else{
      data.show=true
      data.hide=false
    }
  }


  async function delete_material(material){
    let response=await axios.post('/meet/delete_material/',{material_id:material.id})
  }



</script>



<style scoped>

    .material-container{
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        min-height: 200px;
    }


    .upload-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding:5px;
      border:var(--border);
      overflow: hidden;

    }

    .upload-input-container{
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    .download-container{
        width:fit-content;
        height:auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap:5px;
        align-items: center;
        justify-content: center;

    }

    .delete-material{
      background: var(--darkred);
      color: var(--lightred);
    }



    .material{

        display:flex;
        justify-content: center;
        align-items: center;
        gap:5px;

        border:var(--border);
        background:var(--green);
        padding: 10px;

    }






  @media (max-width:1000px){

    .material-container{
      flex-direction: column;
    }

  }


















    .show_upload{

animation-name: show_upload;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */
/* animation-iteration-count: iteration-count; */
animation-direction: normal;
animation-fill-mode: forwards;
max-height:fit-content;
--height:fit-content;
--width:100%;
/* max-width:200px; */


}



@keyframes show_upload {
  0%{
    /* min-width:0%; */
    width:0%;
    /* max-width:0%; */
    min-height:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
    gap:0px;
  }

  100%{
    /* min-width:var(--width); */
    width:var(--width);
    /* max-width:var(--width); */
    min-height:var(--height);
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);
    gap:var(--gap);

    
  }

}




.hide_upload{


animation-name: hide_upload;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */

animation-direction: normal;
animation-fill-mode: forwards;
}




@keyframes hide_upload {
  0%{
    min-width:var(--width);
    width:var(--width);
    max-width:var(--width);
    min-height:var(--height);
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);
    gap:var(--gap);
  }

  100%{
    min-width:0%;
    width:0%;
    max-width:0%;
    min-height:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
    gap:0px;
  }
}


</style>
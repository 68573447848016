<template>

  <!-- <span class="navigate" @click.stop=toggleNavBar>
    <img :src="logo">


  </span> -->

  
  
  <nav @click="toggleNavBar" :class="{show_navbar:toggle}">
    <img @click.stop="toggleNavBar" class="navigate" :src="logo" :class="{move:toggle}">
    <template v-if="!isAuthenticated">
      <router-link to="/">Home</router-link>
    </template>

    <template v-if="isAuthenticated">        
        
        <template v-if="['Admin'].includes(role)">
          <router-link to="/admin-dashboard">Dashboard</router-link>
          <router-link to="/database">DB</router-link>
          
        </template>
        
      <template v-if="['Instructor'].includes(role)">
        <router-link to="/instructor-dashboard">Instructor Dashboard</router-link>
        <router-link to="/hoursheet">Hoursheet</router-link>
        
      </template>
      
      <template v-if="['Student'].includes(role)">
        <router-link to="/student-dashboard">Student Dashboard</router-link>
        
      </template>

      <router-link to="/profile">Profile</router-link>
      
      <!-- Asking for lesson tickets -->
      <!-- <router-link to="/profile">Material Under construction</router-link> -->
      
    </template>
    



    <!-- <router-link to="/signup">Sign Up</router-link> -->
    

    <template v-if="!isAuthenticated">
      <router-link to="/login">Login</router-link>
    </template>
    <template v-else>
      <router-link to="/logout">Logout</router-link>
    </template>
  
    <!-- <router-link to="/about">About</router-link> -->
  
  </nav>

</template>


<script setup>
  import {ref} from 'vue'
  import {useStore} from 'vuex'
  let toggle=ref(false)

  let logo=ref()

  logo.value=require('@/assets/logo.png')
  let store=useStore()
  let user=ref(store.state.user)
  let role=ref(null)
  let isAuthenticated=ref(store.state.isAuthenticated)
  function toggleNavBar(){
    user.value=store.state.user
    isAuthenticated.value=store.state.isAuthenticated
    if (user.value==null){
      role.value=null
    }else{
      role.value=user.value.role
    }
    toggle.value=!toggle.value

  }

</script>



<style scoped>

  .navigate{
    position:fixed;
    top: 1rem;
    left:1rem;
    height:4rem;
    width:4rem;
    background:var(--blue);
    opacity:0.8;
    border-radius:50%;
    border: 5px solid var(--white);
    z-index:100;
    cursor:pointer;
  }

  .navigate:hover{
    opacity:1;
  }


  .move{
    position:static;
    /* left:3rem; */
    /* top:0rem */
  }

  .show_navbar{
    left:0;
  }

  nav{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    top: 0;
    left: 120%;
    height: 100vh;
    width: 20rem;
    background: var(--red);
    color: var(--white);
    transition: all 0.5s linear;
    font-size:x-large;
    gap:2rem;
    text-align:center;
    overflow: auto;
    z-index: 100;
  }
  a{
    display: flex;
    width: 100%;
    height: 50%;
    background: var(--blue);
    color: var(--white);
    justify-content: center;
    align-items: center;
    font-family: var(--passion);

  }

  a:hover{
    background: var(--black)
  }
</style>

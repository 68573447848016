<template>
    <div class="title">
        <button @click.stop="toggle_availability()">
            Toggle Availability
        </button>
    </div>

    {{ instructor_user }}

    <div class="availability-container" v-show="data.show">
        <div class="week">
            <div class="times">
                
                <div class="time" v-for="time in times" :data-time="time">{{time}}</div>
            </div>

            <div class="days">
                <div class="day" v-for="day in days">
                    {{day}}
                    <template v-for="(time1value,time1) in data.availability">
                        <template v-if="time1.includes(day)">
                            <div @click.stop="change_availability(time1)" class="time" :data-time="time1" :class="{available:time1value=='available',not_available:time1value=='not available'}">
                                <template v-if="time1value!='available'">

                                    {{ time1value }}

                                </template>
                                <template v-else>
                                    {{ time1 }}
                                </template>
                            </div>
                        </template>

                    </template>
                </div>
            </div>


        </div>
    </div>
</template>


<script setup>

import {ref,reactive,inject, onMounted} from 'vue'
import axios from 'axios'

let availabilitydata=inject('availabilitydata')
let data=reactive({
    show:false,
  })


let days=ref([
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
])


let times=ref({
    '5:00 AM': '05:00',
    '5:30 AM': '05:30',
    '6:00 AM': '06:00',
    '6:30 AM': '06:30',
    '7:00 AM': '07:00',
    '7:30 AM': '07:30',
    '8:00 AM': '08:00',
    '8:30 AM': '08:30',
    '9:00 AM': '09:00',
    '9:30 AM': '09:30',
    '10:00 AM': '10:00',
    '10:30 AM': '10:30',
    '11:00 AM': '11:00',
    '11:30 AM': '11:30',
    '12:00 PM': '12:00',
    '12:30 PM': '12:30',
    '1:00 PM': '13:00',
    '1:30 PM': '13:30',
    '2:00 PM': '14:00',
    '2:30 PM': '14:30',
    '3:00 PM': '15:00',
    '3:30 PM': '15:30',
    '4:00 PM': '16:00',
    '4:30 PM': '16:30',
    '5:00 PM': '17:00',
    '5:30 PM': '17:30',
    '6:00 PM': '18:00',
    '6:30 PM': '18:30',
    '7:00 PM': '19:00',
    '7:30 PM': '19:30',
    '8:00 PM': '20:00',
    '8:30 PM': '20:30',
    '9:00 PM': '21:00',
    '9:30 PM': '21:30',
    '10:00 PM': '22:00',
    '10:30 PM': '22:30',
    '11:00 PM': '23:00',
    '11:30 PM': '23:30',
})


onMounted(()=>{
    console.log('initialized',availabilitydata.user)
})

async function toggle_availability(){
    data.show=!data.show
    console.log('toggling',availabilitydata.user)
    if (data.show){
        let response=await axios.post('/instructors/get_availability/',{instructor_user_id:availabilitydata.user.id})
        data.availability=response.data
    }
    else{
        data.availability=[]
    }
}


async function change_availability(time1){
    

    if (data.availability[time1]=='available'){
        data.availability[time1]='not available'
    }else if (data.availability[time1]=='not available'){
        data.availability[time1]='available'
    }

    
    let availability=[]
    for (let key in data.availability){

        if (data.availability[key] =='not available'){
            availability.push(key)
        }

    }


    let response=await axios.post('/instructors/update_availability/',{instructor_user_id:availabilitydata.user.id,availability:availability})


 


}



</script>


<style scoped>

    .availability-container{
        width: 100%;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    @media (max-width:600px) {
        .availability-container{
            justify-content: start;

        }
    }

    .week{
        display: grid;
        justify-content: center;
        align-items: center;
        width: fit-content;
        grid-template-areas: ".     days"
                             "times days";
    }


    .days{
        display: flex;
        align-items: center;
        justify-content: center;
        grid-area:days;
    }

    .day{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .times{
        display: flex;
        flex-direction: column;
        grid-area: times;

    }

    .time{
        border: 2px solid var(--black);
        display: flex;
        align-items: start;
        justify-content: center;
        padding: 1px;
        min-width: 100px;
        width:100%;
        max-width: 100px;
        
        font-size: x-small;

        height: 20px;
        
        background: var(--blue);
        color:var(--white);

        overflow: hidden;

        cursor: pointer;
    }


    .available{
        background: var(--green);

    }

    .not_available{
        background:var(--red);
    }



</style>
<template>
  <div class="move-container" v-show="show">
  
    <div class="parents" >
      <div @click.stop="toggleselected(parent)" class="parent" :class="{selected:parent.id in selected}" v-for="parent in parents">
        {{ parent.id }} | {{ parent.ui }} 
  
  
      </div>
    </div>
    <div class="buttons">
      <button @click.stop="move">
        Move
      </button>
      <button @click.stop="copy">
        Copy
      </button>
    </div>
  
  
  </div>


  
</template>


<script setup>

  import axios from 'axios';
import {ref} from 'vue'
  

  let selectedchildren=ref({})
  let selected=ref({})
  let parents=ref({})
  let show=ref(false)
  let basemodels=ref(null)


  function toggleselected(parent){
    if (parent.id in selected.value){
      delete selected.value[parent.id]
      return
    }

    selected.value[parent.id]=parent

  }


  async function move(){
    console.log(selected.value,selectedchildren.value);
    let response=await axios.post('/database/move_objects/',{parents:selected.value,children:selectedchildren.value})
    console.log(response);
    selected.value={}
    selectedchildren.value={}
    parents.value={}
    show.value=false

    
  }


  async function copy(){
    console.log(selected.value,selectedchildren.value);
    let response=await axios.post('/database/copy_objects/',{parents:selected.value,children:selectedchildren.value})
    console.log(response);
    selected.value={}
    selectedchildren.value={}
    parents.value={}
    show.value=false


  }




  defineExpose({
    parents,
    show,
    selectedchildren,
  })







</script>


<style scoped>

  .move-container{
    position: absolute;
    display: grid;
    width: 50vw;
    height: 50vh;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: var(--secondary);
    justify-content: center;
    align-items: center;
    grid-area: 1/1;
    grid-template-columns: 10fr 1fr;
    overflow: auto;

  }

  .parents{
    display: grid;
    justify-content: center;
    align-items: center;
    grid-area: 1/1;
    gap: 1rem;


  }

  .parent{
    background: var(--primary);
    border: 5px solid black;
    box-shadow: -5px -5px black;
    text-align: center;
    transition: all 0.2s linear;
    width: 5rem;
    height: 5rem;
  }

  .buttons{
    grid-area: 1/2;
    align-self: start;
    position: sticky;
    top: 0;
    right: 0;
  }

  .buttons button{
    border-radius: 1rem;
    height: 3rem;
  }

  .selected{
    background: var(--selected);
  }


</style>
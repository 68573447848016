<template>

    <form @submit.prevent='signup' id="signup" class="login" method="post">
      <label>Email:
        <input name="email" type="text" placeholder="E-mail">
      </label>
      <label>Username:
        <input name="username" type="text" placeholder="Username">
      </label>
      <label>First Name:
        <input name="first_name" type="text" placeholder="First Name">
      </label>
      <label>Last Name:
        <input name="last_name" type="text" placeholder="Last Name">
      </label>
      <label>Cellphone:
        <input name="cellphone" type="text" placeholder="Cellphone">
      </label>
      <label>Age:
        <input name="age" type="number" placeholder="Age">
      </label>
      <label>Location:
        <input name="location" type="text" placeholder="Location">
      </label>
      <label>Timezone
        <input name="timezone" type="text" placeholder="timezone">
      </label>

      <label>Password
        <input name="password" type="password" placeholder="Password">
      </label>

      <button type="submit" name="button">Login</button>
    </form>


</template>

<script setup>

import axios from 'axios'
import {ref} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'

let store =useStore()
let router = useRouter()
 async function signup(e){
  let formdata=new FormData(e.target)
  console.log(e.target);
  // formdata=Object.fromEntries(formdata.entries())
  console.log(formdata);
  try {
    let response= await axios.post('/auth/users/',formdata)
    console.log(await response);
    router.push('/login/')

  } catch (error) {
    console.log(error);
    let errors=error.response.data
    store.dispatch('handleErrors',errors)
}

}



</script>





<style scoped>
  .login{
    top: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  input{
    margin: 1rem;
  }
</style>

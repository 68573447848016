<template>
 
  <div class="sections">

    <div class="section">
      <div class="call-to-action">
        <a class="sign-in" href="/login/">
          <button class="sign-in-button">
            Sign-in/Ingresar
          </button>
        </a>
        


        <div class="call-to-action-text">
          La mejor forma de consolidar tus habilidades de conversación, es con práctica real
        </div>



        <!-- <div class="contact-form">
          <div class="contact-form-background"></div>
          <span>
            <input placeholder="Nombre">
          </span>
          <span>
            <input placeholder="# de contacto">
          </span>
          <span>
            <input placeholder="e-mail">
          </span>
          <span>
            <textarea placeholder="Comentario"/>
          </span>
          <button class="contact-form-button">Solicitar Información</button>

        </div> -->



        <div class="phone-contact">
          <a href="https://wa.me/525515902969?text=Hola,%20busco%20informacion%20sobre%20las%20clases%20de%20ingl%C3%A9s">
            <img class="whatsapp" src="@/assets/images/whatsapp-button.png">
          </a>
          <a href="tel:+525515902969">
            <div class="number">
              Llamar
            </div>
          </a>

        </div>

      </div>
  
    </div>
  
    <div class="section">
      <div class="uniqueness">
        <img class="unique-logo" :src="logo">

        <div class="attribute unique-conversation">
          <div class="unique-title">100% Conversación</div>
          <div class="unique-description">"Curso conversacional en inglés: Aprende de forma práctica y dinámica a comunicarte fluidamente en inglés, desarrollando tus habilidades de conversación."</div>
        </div>
        <div class="attribute unique-native">
          <div class="unique-title">Profesores Nativos</div>
          <div class="unique-description">Sumérgete en el idioma con instrucción de hablantes nativos, mejora tu pronunciación, gramática y comprensión, y adquiere una perspectiva cultural auténtica.</div>
        </div>
        <div class="attribute unique-personalization">
          <div class="unique-title">Personalización</div>
          <div class="unique-description">Descubre un curso de inglés único que se adapta a tu estilo de aprendizaje, con materiales personalizados y atención individualizada.</div>
        </div>
        <div class="attribute unique-flexibility">
          <div class="unique-title">Flexibilidad</div>
          <div class="unique-description">Nuestro curso de inglés flexible te permite elegir tus horarios de clase, adaptándolos a tu agenda. Nuestros profesores altamente capacitados te guiarán a tu propio ritmo para lograr tus objetivos de aprendizaje de manera efectiva</div>
        </div>

      </div>

  
    </div>

    <div class="section course">
      <div class="course-image">
        <img  src="https://i.pinimg.com/564x/9d/26/9b/9d269bd2d9456c8c0dcfb2e90b16dfdc.jpg">
      </div>
      <div class="course-description">
        Entendemos las necesidades de los ejecutivos, personas empresariales y gerentes. Por eso, ofrecemos programas flexibles y personalizados, diseñados específicamente para el mundo empresarial. Nuestro enfoque se centra en mejorar las habilidades de comunicación en inglés, adquirir fluidez en presentaciones y negociaciones, y expandir el vocabulario y la comprensión en temas profesionales. ¡Con nuestras clases, podrás llevar tu carrera al siguiente nivel y alcanzar el éxito internacional que deseas!
      </div>
    </div>

    
    <div class="section course">
      <div class="course-image">
        <img  src="https://i.pinimg.com/564x/a4/f2/f9/a4f2f9604a2b709e5852223f78803d02.jpg">
      </div>
      <div class="course-description">
        Brindándote una experiencia de aprendizaje completa y dinámica para estudiantes de todas las edades y niveles. Nuestros programas se adaptan a tus necesidades individuales, ofreciendo una amplia gama de recursos interactivos y materiales didácticos actualizados. Aprenderás gramática, vocabulario y habilidades de comunicación de manera efectiva, a través de clases interactivas impartidas por profesores altamente calificados. ¡Descubre el mundo del inglés y desbloquea nuevas oportunidades con nosotros!
      </div>
    </div>


    <div class="section course">
      <div class="course-image">
        <img  src="https://i.pinimg.com/564x/bf/74/b3/bf74b320a5e3046a4d4dfb6ce375270f.jpg">
      </div>
      <div class="course-description">
        Programas de preparación para certificaciones reconocidas internacionalmente. Nuestros cursos están diseñados para ayudarte a alcanzar el éxito en exámenes como el TOEFL, IELTS, Cambridge English, y más. Contamos con profesores especializados en cada certificación, quienes te guiarán y brindarán el apoyo necesario para que te sientas preparado y confiado. Obtén la certificación que necesitas para tus metas académicas, profesionales o de inmigración con nuestro enfoque orientado a resultados.
      </div>
    </div>

    <div class="section course">
      <div class="course-image">
        <img  src="https://i.pinimg.com/564x/12/19/4c/12194c5c011f5057b7cddcc5f82095a4.jpg">
      </div>
      <div class="course-description">
        Contamos con servicios de regularización y preparación para exámenes tanto para estudiantes universitarios como de bachillerato. Nuestros profesores altamente calificados te ayudarán a fortalecer tus habilidades en gramática, vocabulario, comprensión auditiva y expresión oral. Además, te brindaremos estrategias efectivas para superar exitosamente los exámenes académicos como el TOEFL, IELTS y otros. Prepárate para alcanzar el éxito académico y desbloquear nuevas oportunidades con nuestras clases especializadas.
      </div>
    </div>


    <div class="section course">
      <div class="course-image">
        <img  src="https://i.pinimg.com/564x/08/4c/e8/084ce80e35f9bb7af9a09cbea7b122b1.jpg">
      </div>
      <div class="course-description">
        Enfocados en brindar una experiencia de aprendizaje divertida y efectiva para niños y niñas. Nuestros programas están diseñados especialmente para su edad y nivel de habilidad, utilizando materiales interactivos y juegos educativos. Nuestros profesores altamente capacitados y amigables crearán un ambiente acogedor donde los niños se sientan motivados y cómodos para aprender inglés. Con nuestras clases, los niños desarrollarán habilidades lingüísticas sólidas y confianza en su comunicación en inglés desde una edad temprana.

      </div>
    </div>


<!-- 
    <div class="section contact">

    </div> -->


    <div class="section footer">
      <a href="https://wa.me/525515902969?text=Hola,%20busco%20informacion%20sobre%20las%20clases%20de%20ingl%C3%A9s">
        Contact
      </a>

      <div>Terms & Conditions</div>
      


    </div>

  </div>
  
 <HomeAnimation/> 
  
  
</template>
  
 <script setup>
import {ref} from 'vue'
import HomeAnimation from '@/components/HomeAnimation.vue'

let logo=ref()

logo.value=require('@/assets/logo.png')



</script> 
  
  
  
  
  
  
  
  
  
<style scoped>

  

  .sections{
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .section{
    height: 100vh;
    width: 100%;
    max-width: 100%;
    background:var(--blue);

    width: 0;
    opacity: 0;
    border-radius: 0% 50% 50% 0%;



    animation-name: left-sections;
    animation-duration: calc(var(--home)/2);
    animation-timing-function: ease-in-out;
    animation-delay: calc(var(--home)*2);
    /* animation-iteration-count: iteration-count; */
    animation-direction: normal;
    animation-fill-mode: forwards;



  }


  .section:nth-child(2n){

    background: var(--red);
    align-self: flex-end;


    animation-name: right-sections;
    animation-duration: calc(var(--home)/2);
    animation-timing-function: ease-in-out;
    animation-delay: calc(var(--home)*2);
    /* animation-iteration-count: iteration-count; */
    animation-direction: normal;
    animation-fill-mode: forwards;

  }


  @keyframes left-sections{
    0%{
      width: 0;
      opacity: 0;
      border-radius: 0% 50% 50% 0%;
    }

    90%{
      border-radius: 0% 10% 10% 0%;
    }

    100%{
      width: 100vw;
      opacity: 1;
      border-radius: 0%;
    }
  }

  @keyframes right-sections{
    0%{
      width: 0;
      opacity: 0;
      border-radius: 50% 0% 0% 50%;
    }

    90%{
      border-radius: 10% 0% 0% 10%;
    }

    100%{
      width: 100vw;
      opacity: 1;
      border-radius: 0%;
    }
  }


  .call-to-action{
    display:grid;
    height:100%;
    width:100%;
    justify-items: center;
    align-items:center;
    /* grid-template-columns: auto fit-content; */
    grid-template-rows: min(10%) fit-content fit-content;

    background-image:url('@/assets/landingpage.png');
    background-size: cover;
    background-position:center;
    


    opacity:0;

    animation-name: call-to-action;
    animation-duration: calc(var(--home)/2);
    animation-timing-function: ease-in-out;
    animation-delay: calc(var(--home)*2);
    /* animation-iteration-count: iteration-count; */
    animation-direction: normal;
    animation-fill-mode: forwards;
    overflow:hidden;

    grid-template: 
                    ". . sign-in"
                    ". phone-contact ."
                    "call-to-action-text call-to-action-text call-to-action-text"
                    /1fr 2fr 1fr;

  }



  /* NOT USED */
  /* .call-to-action-logo{
    position:absolute;
    top:1rem;
    left:1rem;
    height:4rem;
    width:4rem;
  } */


  .sign-in{
    /* grid-area:1/2/1/2; */
    grid-area:sign-in;
    align-self:center;
    justify-self:center;
  }
  .sign-in-button{

    background:var(--red);
    color:var(--white);
    border-radius:1rem;
    padding:1rem;
    font-family:var(--passion);
    font-size:xx-large;
  }

  .sign-in-button:hover{
    background:var(--black);
    
  }


  .call-to-action-text{
    align-self:center;
    justify-self:center;
    text-align:center;
    color:var(--white);
    text-shadow: var(--shadow);

    font-size:xxx-large;
    grid-area:call-to-action-text;
    align-self: end;

    margin:2rem;
    font-family:var(--passion);
  }

  .contact-form{
    /* background:linear-gradient(var(--red1),var(--blue1)); */
    position:relative;
    /* grid-area:2/1/2/3; */
    align-self:center;
    justify-self:center;
    /* height:100%; */
    /* width: 100%; */
    border-radius: 1rem;
    border: 5px solid var(--white);
    margin:2rem;
    padding: 10px;
    display:flex;
    flex-direction:column;
    gap:0.7rem;
    justify-content:center;
    align-items:center;
    overflow:hidden;
  }

  .contact-form-background{
    width:100%;
    height:100%;
    position:absolute;
    background:linear-gradient(var(--blue),var(--green));
    opacity:0.8;
    z-index:-1;

  }

  .contact-form-button{
    font-family:var(--passion);
    padding:1rem;
    font-size:x-large;
    border-radius:1rem;
    background:var(--red);    
    color:var(--white);    
  }
  .contact-form-button:hover{
    background:var(--green);   
  }

  .phone-contact{
    align-self:center;
    justify-self:center;


    display: flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    grid-area:phone-contact;
    margin:0.5rem;

  }

  .whatsapp{
    z-index:1;
    height:3rem;
    /* border-radius:50%; */
    
  }
  .number{
    font-family:var(--passion);
    text-align:center;
    text-justify:center;
    font-size:xx-large;
    min-width:calc(fit-content+0.4rem);
    color:var(--white);
    text-shadow:var(--shadow);


  }


  @keyframes call-to-action{
    0%{
      opacity:0;
      transform: scale(2);
    }
    100%{
      opacity:1;
      transform: scale(1);
    }

  }





  @media(max-width:500px){
    .call-to-action{
        background-position:center;

        grid-template: 
                    "sign-in"
                    "phone-contact"
                    "call-to-action-text"
                    /1fr ;
      }




    .number{
      font-size:xx-large;
    }

 

    .call-to-action-text{
      grid-area:4/1;
    }




  }







  .uniqueness{
    display:grid;
    width:100vw;
    height:100vh;
    align-items:center;
    justify-items:center;
  }
  .unique-logo{
    height:max(20vh,20vw);
    grid-area:1/1/3/3;
   }
  
  .attribute{
    display:grid;
    gap:1rem;
    /* max-width:100%; */
    margin:1rem;
    overflow:auto;
  }

  .unique-title{
    font-size:x-large;
    color:var(--white);
    max-width:100%;
    text-align:center;
  }

  .unique-description{
    color: var(--gray);
    font-size: medium;
    max-width:20rem;
    word-break:break-word;
    text-align:center

  }

  .unique-conversation{
    grid-area:1/1;
  }
  .unique-native{
    grid-area:2/1;
  }

  .unique-personalization{
    grid-area:1/2;
  }

  .unique-flexibility{
    grid-area:2/2;
  }



  .course{
    display:grid;
    width:100vw;
    height:100vh;
    justify-items:center;
    align-items:center;
    grid-template-columns: 50vw 50vw;
    grid-template:"description image"
    
  }

  .course:nth-child(2n){
    grid-template:"image description"
  }

  .course-description{
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:larger;
    color:var(--white);
    max-width: 20rem;
    height:100%;
    text-align:center;
    text-justify:center;
    padding:1rem;

    grid-area:description;
  }

  .course-image{
    display:flex;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    height:min(50vw,50vh);
    width:min(50vw,50vh);
    /* padding:1rem; */

    grid-area:image
  }

  .course-image img{
    border-radius:50%;
    max-width:100%;
    max-height:100%;
  }

  @media(max-width:1000px){
    .course{

      grid-template:"image"
                    "description";
      grid-template-rows:50vh 50vh;
    }
    .course:nth-child(2n){
      grid-template:"image"
                    "description";
      grid-template-rows:50vh 50vh; 
    }


  }


  @media(max-height:500px){
    .course-description{
      max-width:100vw;
    }
  }



  .footer{
    height:5rem;
    display:flex;
    gap: 2rem;
    justify-content:space-around;
    align-items:center;
    
  }



</style>
  

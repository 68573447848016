<template>
    <div @click.stop="toggle_tickets()" class="title">Tickets</div>
    <div class="tickets-container" :class="{show:data.show,hide:!data.show}">
        <div class="ticket" :class="{ [ticket.status]: true }" v-for="ticket in data.tickets">
            <div class="subtitle">
                {{ ticket.title }}
            </div>
            <div>{{ ticket.instructor.ui }}</div>
            <div>{{ ticket.group_ui }}</div>
            <label>
                Created at
                <div>{{ ticket.created_at }}</div>
            </label>
            <label>
                -Original Schedule-
                <div>{{ ticket.original_schedule }}</div>
            </label>
            <label>
                -Proposed Schedule-
                <div> {{ ticket.proposed_schedule }}</div>
            </label>
            <hr>
            <div class="buttons">
                <button @click.stop="accept_schedule(ticket)" class="accept">Accept</button>
                <button @click.stop="reject_schedule(ticket)" class="reject">Reject</button>

            </div>

        </div>
    </div>
</template>



<script setup>
    import {ref,reactive,onBeforeMount} from 'vue'
    import axios from 'axios'


    let data=reactive({
        show:false,
        tickets:[],
        ticket:{}
    })

    onBeforeMount(async ()=>{
        let response=await axios.post('/tickets/get_tickets/')
        data.tickets=response.data
    })

    function toggle_tickets(){
        data.show=!data.show
    }

    async function accept_schedule(ticket){
        let response=await axios.post('/tickets/accept_schedule/',{ticket_id:ticket.id})
        Object.assign(ticket,response.data)

    }

    async function reject_schedule(ticket){
        let response=await axios.post('/tickets/reject_schedule/',{ticket_id:ticket.id})
        Object.assign(ticket,response.data)

    }

</script>


<style scoped>

    .title{
        cursor: pointer;
    }
    .tickets-container{
        display: grid;
        grid-template-columns:repeat(auto-fit,400px);
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100vw;
        height: auto;
        overflow: auto;
        text-align: center;

    }

    .ticket{
        width: fit-content;
        border: var(--border);
        background: var(--gray);
        color:var(--black);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        
    }

    .accept{
        background: var(--darkgreen);;
    }

    .reject{
        background: var(--red);;
    }


    .pending{
        background: var()
    }

    .accepted{
        background: var(--green);
    }

    .rejected{
        background: var(--red);
    }

</style>

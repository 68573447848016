<template>
    <div class="durations-container">
      
        <template v-for="duration in durations">
            <template v-if="data.duration==duration">
                <button class="selected">
                    {{ duration }}
                </button> 
    
            </template>
            <template v-else>
                <button @click.stop="update_duration(duration)">
                    {{ duration }} hrs
                </button> 
    
            </template>
        </template>
    </div>
</template>


<script setup>
  import {ref,reactive,inject,provide,onUpdated} from 'vue'
  import axios from 'axios'
  
  let data = reactive()
  data=inject('duration_data')


  let durations=ref([
    "0.25",
    "0.50",
    "0.75",
    "1.00",
    "1.25",
    "1.50",
    "1.75",
    "2.00",
    "2.50",
    "3.00",
    "3.50",
    "4.00",
  ])

  function update_duration(new_duration){
    data.duration=new_duration

  }







</script>


<style scoped>


.durations-container{
    /* position:relative; */
    height:auto;
    width: max-content;
    /* font-size:0px; */
    display:flex;
    align-items:center;
    justify-content: center;
    overflow:auto;
    gap:10px;
}

.selected{
    background:var(--green);
    width: 50px;
    height: 50px;
    font-size: x-large;
    padding:5px;
}








</style>
<template>
  <section class="fullscreen">
    
    <div class="title">Database</div>
    <div class="objects">

      <template v-for="model in models">
        <div class="object" @click.stop="get_objects(model)">{{ model }}s</div>
  
      </template>

    </div>




  </section>

  <Pane ref="pane"/>



</template>

<script setup>
  import {ref} from 'vue'
  import axios from 'axios';
  import Pane from '@/components/Pane.vue'

  let pane=ref()

  let sectionfullscreen=ref()
  let models=ref([
    'User',
    'Group',
    'Student',
    'Instructor',
    'Payment',
    'Package',
    'Attendance',
    'Student_Confirmation',
    'Instructor_Confirmation',
    'Material',
    'Evaluation',

  ])



  
  async function get_objects(model){
    // let objects=await axios.get(`/database/get_objects/${model}/`)
    console.log(axios.defaults.headers.common['Authorization'])

    let response=await axios.post(`/database/get_objects/`,{model:model})
    pane.value.objects=response.data
    pane.value.sectionfullscreen=true

    pane.value.currentmodel=model
    // let objects=await axios.get('/database/')
  }




</script>









<style scoped>

  section {
    background: var(--blue1);
    width: 100vw;
    color: var(--white1);
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    top: -100vh;
    transition: all 0.2s linear;


  }


  
  .title{
    font-size: xx-large;
    color: var(--white1);
    text-align: center;
    text-justify: center;
    height: auto;
  }


  .objects {
    background: var(--blue1);
    display: grid;
    height: 100%;
    width: inherit;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit,minmax(5rem,20rem));
    /* grid-template-rows: repeat(auto-fit,minmax(1rem, 7rem)); */
    overflow:auto;
    gap:1rem;
    

  }


  .object{
    background: var(--blue1);
    box-shadow: -5px -5px var(--blue2);
    border: 5px solid var(--blue3);
    text-align: center;
    display: grid;
    /* align-items: center; */
    justify-content: center;
    /* align-self: center; */
    /* gap: 2rem; */
    transition: all 0.2s linear;
    /* grid-template-rows: repeat(auto-fit,1rem); */

    font-size: xx-large;
    overflow:auto;
    overflow-wrap:break-word;


    min-width:fit-content;
    min-height:15rem;

  }

  .entries{
    background: var(--yellow1);
    display: grid;
    position:fixed;
    top:3rem;
    /* grid-template-rows: repeat(auto-fit,auto); */
    width: inherit;
    height: inherit;
    overflow:auto;
    gap:1rem;
    font-size:xx-large;

  }

  .subentries{
    display: grid;
    grid-template-columns: repeat(auto-fit,10rem);
    justify-content: center;
    align-items: center;
    gap:1rem;

  }

  .entry{
    background: var(--blue1);
    box-shadow: -5px -5px var(--blue2);
    border: 5px solid var(--blue3);
    text-align: center;
    transition: all 0.2s linear;
    font-size:x-large;
    overflow-wrap:anywhere;
    min-width:fit-content;
    min-height:15rem;

  }


  .fullscreen {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0; 
    position: fixed;
    
  }

  textarea{
    width: 100%;
    height:10rem;
    text-align:center;
    font-size:xx-large;
    overflow-wrap:anywhere;

  }

  .buttons{
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .buttonsshow{
    background: var(--yellow2);
    border-radius: 50%;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    height: 2rem;
    width: 2rem;
  }


  .buttons button{
    border-radius: 1rem;
    height: 3rem;
  }

  .selected{
    background: var(--yellow1);
  }



</style>
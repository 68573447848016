<template>
  <div class="introduction">
    <div class="title">
      Introduction
    </div>

    <div class="level">Level: {{ level }}</div>

    <div class="description">{{ description }}</div>

    <div class="objectives">
      <div class="objective" v-for="objective in objectives">{{ objective }}</div>
    </div>

    <div class="duration"> Estimated time: {{ duration }} minutes</div>




  </div>
</template>


<script setup>
  import {ref} from 'vue'


  let level=ref()
  let description=ref()
  let objectives=ref()
  let duration=ref()


  defineExpose({
    level,
    description,
    objectives,
    duration,

  })


</script>


<style scoped>

  .introduction{
    display: grid;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    /* max-width: 100vw; */


  }
  .title{
    font-size: 2rem;
  }


</style>